<template>
  <div class="attachments">
    <Header
      back="返回"
      title="财务列表"
      index="首页"
      titleOne="财务管理"
      titleTwo="财务审核"
      beforeTitle="详情"
    />
    <div class="content">
      <el-form
        :rules="formRule"
        ref="formRule"
        :model="form"
        label-width="80px"
        :label-position="labelPosition"
      >
        <h1>财务信息</h1>
        <ul>
          <li><span>企业名称</span>{{ this.form.company_name }}</li>
          <li><span>合同名称</span>{{ this.form.contract_name }}</li>
          <li><span>项目名称</span>{{ this.form.product_name }}</li>
          <li><span>签单人</span>{{ this.form.signatory }}</li>
          <li><span>签单时间</span>{{ this.form.down_time }}</li>
          <li><span>应收款（元）</span>{{ this.form.receivables }}</li>
          <li><span>已收款（元）</span>{{ this.form.received }}</li>
          <li><span>待收款（元）</span>{{ this.form.uncollected }}</li>
          <li>
            <span>状态</span>
            <i v-if="this.form.finance_status == 1">未核销</i>
            <i v-if="this.form.finance_status == 2">核销</i>
            <i v-if="this.form.finance_status == 3">退款中</i>
            <i v-if="this.form.finance_status == 4">已退款</i>
          </li>
        </ul>
        <h1>收款模式</h1>
        <ul>
          <li>
            <span>收费方式</span>
            <i v-if="this.form.billing_method == 1">固定</i>
            <i v-if="this.form.billing_method == 2">计提</i>
          </li>
          <li><span>总金额（元）</span>{{ this.form.total_money }}</li>
          <li><span>定金（元）</span>{{ this.form.deposit }}</li>
          <li><span>提交（元）</span>{{ this.form.submit_money }}</li>
          <li><span>通过（元）</span>{{ this.form.pass_money }}</li>
          <li><span>资金下达（元）</span>{{ this.form.release_money }}</li>
          <li><span>验收确认（元）</span>{{ this.form.check_money }}</li>
        </ul>
        <h1>专利年费</h1>
        <el-table :data="form.collection" style="width: 100%">
          <el-table-column label="批次">
            <template slot-scope="scope">
              <el-input v-model="scope.row.batch" disabled></el-input>
            </template>
          </el-table-column>
          <el-table-column label="收款金额">
            <template slot-scope="scope">
              <el-input v-model="scope.row.money" disabled></el-input>
            </template>
          </el-table-column>
          <el-table-column label="收款时间">
            <template slot-scope="scope">
              <el-date-picker
                v-model="scope.row.collection_time"
                type="date"
                placeholder="选择日期"
                disabled
              >
              </el-date-picker>
            </template>
          </el-table-column>
          <el-table-column label="备注">
            <template slot-scope="scope">
              <el-input v-model="scope.row.remark" disabled></el-input>
            </template>
          </el-table-column>
        </el-table>
      </el-form>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      labelPosition: "top",
      form: {
        company_name: "",
        contract_name: "",
        product_name: "",
        signatory: "",
        down_time: "",
        receivables: "",
        received: "",
        finance_status: "",
        billing_method: "",
        total_money: "",
        deposit: "",
        submit_money: "",
        pass_money: "",
        release_money: "",
        check_money: "",
        collection: [],
      },
      formRule: {},
    };
  },
  computed: {
    meber_id() {
      return this.$route.query.id;
    },
  },
  mounted() {
    if (this.meber_id) {
      this.axios
        .get("/api/finance/view", {
          params: {
            contract_project_id: this.meber_id,
          },
        })
        .then((res) => {
          this.form = res.data;
        });
    }
  },
};
</script>

<style scoped="scoped">
ul li {
  color: #909399;
  font-size: 14px;
  margin-bottom: 10px;
}

ul li span {
  width: 100px;
  display: inline-block;
  color: #cbcfe3;
}
i {
  font-style: normal;
}
</style>
